<template>
    <div>
        <mdb-btn color="primary" title="選取檔案" @click="showDialog = true">
            <mdb-icon icon="image" />
            &nbsp;選取檔案
        </mdb-btn>
        <mdb-modal size="lg" :show="showDialog" @close="showDialog = false">
            <mdb-modal-header>
                <mdb-modal-title>媒體庫</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <iframe
                    :src="
                        `/filemanager/dialog.php?ADM_ID=${$store.state.user.id}&token=${$store.state.user.login_token}&type=${this.type}&field_id=${field_id}&crossdomain=1&relative_url=1`
                    "
                    frameborder="0"
                    style="min-height:80vh;width:100%;height:100%"
                ></iframe>
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>
<script>
    import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbBtn, mdbIcon } from 'mdbvue';
    export default {
        components: {
            mdbModal,
            mdbModalHeader,
            mdbModalTitle,
            mdbModalBody,
            mdbBtn,
            mdbIcon,
        },
        props: { type: { default: '1' } },
        data() {
            return {
                showDialog: false,
                field_id: Math.ceil(Math.random() * 100),
                file: '',
            };
        },
        watch: {
            showDialog() {
                if (this.showDialog) {
                    window.addEventListener('message', this.OnMessage);
                } else {
                    window.removeEventListener('message', this.OnMessage);
                }
            },
            file() {
                this.$emit('input', this.file);
                this.$emit('change', this.file);
            },
        },
        methods: {
            OnMessage(e) {
                // Make sure the sender of the event is trusted
                if (e.data.sender === 'responsivefilemanager') {
                    if (e.data.field_id) {
                        this.file = e.data.url;
                        this.showDialog = false;
                        // Delete handler of the message from ResponsiveFilemanager
                        window.removeEventListener('message', this.OnMessage);
                    }
                }
            },
        },
    };
</script>
