import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
Vue.use(Vuex);
import user from './modules/user';

export default new Vuex.Store({
    state: {
        sidenav: true,

        production_mode: process.env.NODE_ENV === 'production',
        backend_url:
            process.env.NODE_ENV === 'production' ? 'https://daliadmback.moneydodo.idv.tw/' : 'http://daliadmback.localhost.com/',
        menu: '',
    },
    getters: {
        sidenav: (state) => state.sidenav,
        menu: (state) => state.menu,
    },
    mutations: {
        setmenu(state, menu) {
            state.menu = menu;
        },
        toggleSideNav(state, value) {
            if (typeof value === 'boolean') {
                state.sidenav = value;
                return;
            }
            state.sidenav = !state.sidenav;
        },
    },
    actions: {
        getmenu(context) {
            context
                .dispatch('post_form', {
                    payload: {
                        url: 'system/get_menu',
                        data: {},
                    },
                })
                .then((res) => {
                    // console.info(res);
                    context.commit('setmenu', res.data);
                });
        },
        toggleSideNav({ commit }, value) {
            commit('toggleSideNav', value);
        },
        post_form(context, { payload, before }) {
            let store = this;
            if (typeof before === 'function') {
                before();
            }
            if (!this.state.production_mode) {
                console.info('送出表單 payload', payload, 'state', this.state);
            }
            let form = new FormData();
            for (let i in payload.data) {
                form.append(i, payload.data[i]);
            }
            let res = axios.post(`${store.state.backend_url}${payload.url}`, form, {
                headers: {
                    'x-login-token': store.state.user.login_token,
                    'x-admin-id': store.state.user.id,
                },
            });
            res = res.then((res) => {
                console.info(res);
                if (res.data.status && res.data.status == 'logout') {
                    store.dispatch('clear_cookie');
                    location.href = '/';
                    return false;
                }
                return res;
            });

            return res;
        },
        get_form(context, { payload, before }) {
            if (typeof before === 'function') {
                before();
            }
            if (!this.state.production_mode) {
                console.info('payload', payload, 'state', this.state);
            }
            let res = axios.get(`${this.state.backend_url}${payload.url}`, {
                headers: {
                    'x-login-token': this.state.user.login_token,
                    'x-admin-id': this.state.user.id,
                },
            });
            console.info(res);
            return res;
        },
    },
    modules: {
        user,
    },
});
