import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import layout from '../views/layout.vue';
import empty from '../views/empty.vue';
const routes = [
    {
        meta: { title: '後臺管理系統-登入' },
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
        props: (route) => ({
            query: route.query,
        }),
    },
    {
        meta: { title: '後臺管理系統' },
        path: '/',
        component: layout,
        children: [
            {
                path: '/',
                component: () => import('../views/index.vue'),
            },
            {
                path: 'account',
                name: '帳號管理',
                component: empty,
                children: [
                    {
                        path: 'changepw',
                        name: '修改登入密碼',
                        component: () => import('../views/account/changepw.vue'),
                    },
                    {
                        path: 'index',
                        name: '列表',
                        component: () => import('../views/account/index.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                    {
                        path: 'add',
                        name: '新增',
                        component: () => import('../views/account/edit.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                    {
                        path: 'edit',
                        name: '維護帳號',
                        component: () => import('../views/account/edit.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                ],
            },
            {
                path: 'website',
                name: '基本資料維護',
                component: empty,
                children: [
                    {
                        path: 'info',
                        name: '網站設定',
                        component: () => import('../views/website/info.vue'),
                    },
                ],
            },
            {
                path: 'filemmng',
                name: '基本資料維護',
                component: () => import('../views/filemanager.vue'),
            },
            {
                path: 'product',
                component: empty,
                name: '商品資料',
                children: [
                    {
                        path: '',
                        name: '列表',
                        component: () => import('../views/product/list.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                    {
                        path: 'new',
                        name: '新增商品',
                        component: () => import('../views/product/edit.vue'),
                    },
                    {
                        path: 'edit',
                        name: '編輯商品',
                        component: () => import('../views/product/edit.vue'),
                        props: (route) => ({
                            id_product: route.query.id_product,
                        }),
                    },
                    {
                        path: 'class_list',
                        name: '分類列表',
                        component: () => import('../views/product/class_list.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                ],
            },
            {
                path: 'contact',
                component: empty,
                name: '聯絡我們',
                children: [
                    {
                        path: '',
                        name: '列表',
                        component: () => import('../views/contact/list.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                ],
            },
        ],
    },
    {
        path: '*',
        name: '404',
        component: () => import('../views/404.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
