<template>
    <mdb-navbar class="flexible-navbar white" position="top" scrolling double :toggler="false">
        <mdb-navbar-brand @click.native="toggleSideNav" class="button-collapse">
            <mdb-icon icon="bars" />
        </mdb-navbar-brand>
        <!-- <mdb-navbar-brand class="button-collapse">
            <router-link to="/"
                         style="color:#212529">
                <mdb-icon icon="home" />
            </router-link>
        </mdb-navbar-brand> -->
        <mdb-navbar-nav left>
            <mdb-breadcrumb>
                <mdb-breadcrumb-item>
                    <router-link to="/" style="color:#212529">
                        <mdb-icon icon="home" />
                    </router-link>
                </mdb-breadcrumb-item>
                <mdb-breadcrumb-item
                    v-for="(b, bk) in computedPage.bread"
                    :active="bk == computedPage.bread.length - 1"
                    :key="`bread_${bk}`"
                >
                    <router-link :to="b.link">{{ b.name }}</router-link>
                </mdb-breadcrumb-item>
            </mdb-breadcrumb>
        </mdb-navbar-nav>
        <mdb-navbar-nav class="right-nav" right>
            <mdb-dropdown class="nav-item" tag="li">
                <mdb-dropdown-toggle slot="toggle" tag="li" outline="none" class="btn-transparent">
                    <mdb-icon icon="user" /> <span class="clearfix d-none d-sm-inline-block">{{ $store.state.user.name }}</span>
                </mdb-dropdown-toggle>
                <mdb-dropdown-menu left>
                    <!-- <mdb-dropdown-item to="/account/index">帳號管理</mdb-dropdown-item> -->
                    <mdb-dropdown-item to="/account/changepw">修改密碼</mdb-dropdown-item>
                    <mdb-dropdown-item @click="$store.dispatch('logout')">登出</mdb-dropdown-item>
                </mdb-dropdown-menu>
            </mdb-dropdown>
        </mdb-navbar-nav>
    </mdb-navbar>
</template>

<script>
    import {
        mdbNavbar,
        mdbNavbarBrand,
        mdbNavbarNav,
        mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbDropdown,
        mdbDropdownToggle,
        mdbDropdownMenu,
        mdbDropdownItem,
        mdbIcon,
    } from 'mdbvue';

    import { mapActions } from 'vuex';

    export default {
        name: 'Navbar',
        props: {
            page: {
                type: String,
            },
        },
        components: {
            mdbNavbar,
            mdbNavbarBrand,
            mdbNavbarNav,
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbDropdown,
            mdbDropdownToggle,
            mdbDropdownMenu,
            mdbDropdownItem,
            mdbIcon,
        },
        data() {
            return {
                activePage: this.page,
                toggle: false,
            };
        },
        methods: mapActions(['toggleSideNav']),
        computed: {
            computedPage() {
                let bread = [];
                this.$route.matched.forEach((item, index) => {
                    if (index > 0) {
                        bread.push({
                            link: item.path,
                            name: item.name,
                        });
                    }
                });
                return {
                    bread: bread,
                    activePage: this.page,
                };
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .navbar .nav-item {
        line-height: 24px;
        font-size: 16px;
    }
    .navbar .dropdown-toggle {
        padding: 0 10px;
        font-size: 16px;
    }

    .navbar ul {
        padding: 5px 0;
    }

    .navbar .btn {
        font-weight: 300;
    }

    .navbar-collapse {
        order: 0 !important;
    }

    .navbar .notifications-nav .dropdown-menu {
        width: 23rem;
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }

    .btn-transparent {
        background-color: transparent !important;
        color: #000;
        text-transform: none;
        box-shadow: none !important;
    }

    .btn-transparent:hover,
    .btn-transparent:active {
        box-shadow: none !important;
    }
    .navbar-toggler {
        display: none;
    }
</style>
